import Slider from "react-slick"
import Banner from "./components/Banner"
import Breadcrumbs from "./components/Breadcrumbs"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import Header from "./components/Header"
import ServiceBlock from "./components/ServiceBlock"
import testimonial from "./data/testimonial"
import Testimonial from "./components/Testimonial"
import Stats from "./components/Stats"
import Partner from "./components/Partner"
import faq from "./data/faq"
import Accordian from "./components/Accordian"
import WhatIs from "./components/WhatIs"
import solutions from "./data/solution"
import SolutionItem from "./components/SolutionItem"

const Insurer = () => {
    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Partner With Us'
        },
        {
            link: '/assignment',
            label: 'Insurers'
        },

    ]
  const solutionSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Partner with Briezi - Enhance Customer Retention & Persistency",
        subtitle: "Inviting all insurance companies to partner with Briezi for  innovative customer retention and financing solutions.",
        ctaLabel: "Connect Now",
        ctaLink: "/contactus",
        image: "assets/img/insurer-banner.png"
    }
    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <ServiceBlock
                title="Why partner with Briezi?"
                subtitle="By partnering with Briezi, your company can leverage the following benefits:"
                points={["Increase persistency", "Comprehensive Financial Solutions", "Increase Customer Retention", "Advanced Retention Tool", "Dedicated Call Center Team", "Improved Customer Engagement"]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/insurer-why-partner.png"
                type="primary"
                imageLocation="left"
            />
            <div className="p-lg-5  px-lg-80 p-2   solution-box">
                <div className="my-lg-3 my-1">
                    <p className="text-center whatIs-title">{"Our Comprehensive Solutions"}</p>
                </div>
                <div className="px-lg-5 px-2">
                    <p className="text-sm-center text-justify whatIs-description px-5">{"Briezi offers a suite of services designed to help insurers improve customer retention and increase persistency. Our solutions are built to empower policyholders and ensure they continue benefiting from their policies."}</p>
                </div>
                <div className="mb-3 mb-sm-0">
                <Slider {...solutionSettings}>
                    {solutions.map(s => (<SolutionItem {...s} />))}
                </Slider>
                </div>
            </div>
            <div className={"ulip-box row px-5 px-lg-80 px-lg-5"}>
                <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                    <p className={`service-title`}>{"Exclusive Financial Services for ULIP Policies"}</p>
                    <p className={`service-subtitle`}>{"Briezi is the only insurtech company in India offering 'Insurance Premium Financing' and 'Loan on Insurance' services for ULIP policies. We provide unique solutions to meet the financial needs of policyholders while ensuring they continue to benefit from their investments."}</p>
                </div>
                <div className="col-12 col-lg-6 my-3">
                    <img src={"assets/img/ulip.png"} className="slider-img" alt="" />
                </div>
            </div>
            <div className={"success-box row px-5 px-lg-80 px-lg-5"}>
                <div className="col-12 col-lg-6 my-3">
                    <img src={"assets/img/max-success-logo.png"} className="slider-img" alt="" />
                </div>
                <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                    <p className={`service-title`}>{"Success Story with Max Life Insurance"}</p>
                    <p className={`service-subtitle`}>{"Our completely digital and seamless process has successfully increased persistency among Max Life policyholders."}</p>
                    
                </div>
            </div>
            <ServiceBlock
                title="How Our Partnership Works"
                subtitle=""
                points={["Get in Touch: Contact us to discuss your needs and how we can support your goals.","Customize Solutions: We tailor our offerings to align with your specific requirements.","Implementation: Seamlessly integrate our solutions with your existing systems.","Support & Growth: Continuous support and updates to ensure ongoing success."]}
                ctaLabel="Connect Now"
                ctaLink=""
                image="assets/img/insurer-partnership.png"
                type="primary"
                imageLocation="right"
            />

            <Stats />
            <div className="contact-us-section container-fluid">
                <div className="row">
                    <div className="col-12  my-4">
                        <p className="text-center contactus-title">Ready to Partner with Briezi?</p>
                    </div>
                    <div className="col-12">
                        <p className="text-center">Join the growing list of insurers leveraging our solutions to improve their customer engagement and policyholder retention.</p>
                    </div>

                    <div className="d-flex justify-content-center align-item-center col-12 mb-4">
                        <div className="slide-cta primary-button my-3">
                            <a className="nav-link myHref" href={"mailto:info@briezi.com"}  >{"Contact Us"}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.filter(f=>f.type==='general').map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Insurer