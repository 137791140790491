import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import './index.css';
import './style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import createBrowserHistory from 'history/createBrowserHistory'
import Terms from './Terms';
import Privacy from './privacy';
import Verify from './Verify';
import PremiumFinancing from './PremiumFinancing';
import Assignment from './Assignment';
import PolicyLoan from './PolicyLoan';
import AboutUs from './AboutUs';
import Investors from './Investors';
import Insurer from './Insurer';
import Banks from './Banks';
import Distributor from './Distributor';
import ContactUs from './ContactUs';
export const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Route path="/" render={() => <App />} exact />
        <Route path="/premium-financing" render={() => <PremiumFinancing />} exact />
        <Route path="/assignment" render={() => <Assignment />} exact />
        <Route path="/policy-loan" render={() => <PolicyLoan />} exact />
        <Route path="/aboutus" render={() => <AboutUs />} exact />
        <Route path="/terms" render={() => <Terms />} exact />
        <Route path="/privacy" render={() => <Privacy />} exact />
        <Route path="/privacypolicy" render={() => <Privacy />} exact />
        <Route path="/Verify/:token" render={() => <Verify />} />
        <Route path="/investors" render={() => <Investors />} />
        <Route path="/insurers" render={() => <Insurer />} />
        <Route path="/banks" render={() => <Banks />} />
        <Route path="/distributors" render={() => <Distributor />} />
        <Route path="/contactus" render={() => <ContactUs />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
