const solutions = [
    {
        icon: "assets/img/solution-retention-tool.png",
        solution: "SAAS Retention Tool",
        description: "Our SAAS Retention Tool provides insurers with valuable insights and communication tools to engage effectively with policyholders and increase persistency."
    },
    {
        icon: "assets/img/solution-assignment.png",
        solution: "Policy Assignment",
        description: "Through our digital platform, policyholders can assign policies, getting policy value and continue to get risk coverage while addressing their financial needs."
    },
    {
        icon: "assets/img/solution-premium-finance.png",
        solution: "Insurance Premium Financing",
        description: "We offer loans to help policyholders pay their premiums, ensuring they can maintain their coverage without financial strain."
    },
    {
        icon: "assets/img/solution-loan.png",
        solution: "Loan on Insurance",
        description: "Briezi simplifies the process of getting a loan against insurance policies (3-minute digital journey)."
    },
    {
        icon: "assets/img/solution-callcenter.png",
        solution: "Dedicated Call Center Team",
        description: "Our call center team specializes in policyholder retention, offering personalized support to encourage customers to continue their policies. With our advanced retention software, we provide insights to deliver the best financial solutions."
    }
]


export const bankSolution = [
    {
        icon: "assets/img/solution-callcenter.png",
        solution: "Insurance Premium Financing",
        description: "Provide loans to policyholders for paying premiums, ensuring continuous coverage for policyholders. The policyholder assigns the policy to lenders."
    },
    {
        icon: "assets/img/solution-callcenter.png",
        solution: "Policy Assignment",
        description: "Allow customers to assign their policies, offering them financial liquidity while maintaining coverage."
    },
    {
        icon: "assets/img/solution-callcenter.png",
        solution: "Loan On Insurance",
        description: "Offers loans based on surrender/fund value of the insurance policies. Policyholders assign their policies to lenders as collateral."
    }
]

export const distributorSoution = [
    {
        icon: "assets/img/innovative-tools.png",
        solution: "Access to Innovative Tools",
        description: "Our Briezi Partner app offers advanced tools and resources to help you manage and grow your business efficiently."
    },
    {
        icon: "assets/img/revenue-coins.png",
        solution: "Increased Revenue",
        description: "Expand your portfolio with our unique premium financing and policy assignment services, providing your clients with flexible financial solutions."
    },
    {
        icon: "assets/img/engagement.png",
        solution: "Enhanced Customer Engagement",
        description: "Utilize our platform to provide superior customer service and retention strategies, increasing your overall client satisfaction."
    } 
]

export default solutions