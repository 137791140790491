import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import Footer from './Footer'
import Header from './Header'
import success from './success.json'
import failure from './failure.json'
const Verify = () => {
    const [isEmailverified, setIsEmailVerified] = useState(false)
    const validateEmail = React.useCallback(async() => {
        const headers = {
            'content-type': 'application/json',
          }
          const response = await fetch('https://prod.briezi.com/auth/emailverification', {
            method:'GET',
            headers,
          })
          if (response.ok) {
            const res = await response.json()
            if (res.status === 'success') {
                setIsEmailVerified(true)
            } else {
                setIsEmailVerified(false)
            }
          }
    })
    useEffect(() => {
        validateEmail()
    },[])
    return (
        <>
            <a href="#main_content" data-type="section-switch" className="return-to-top">
                <i className="fa fa-chevron-up"></i>
            </a>
            <div className="page-loader">
                <div className="loader">
                    {/* Loader */}
                    <div className="blobs">
                        <div className="blob-center"></div>
                        <div className="blob"></div>
                        <div className="blob"></div>
                        <div className="blob"></div>
                        <div className="blob"></div>
                        <div className="blob"></div>
                        <div className="blob"></div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                                <feBlend in="SourceGraphic" in2="goo" />
                            </filter>
                        </defs>
                    </svg>

                </div>
            </div>{/* /.page-loader */}
            <div id="main_content">
                <Header />
                <section className="banner" style={{ position: 'relative', minHeight: '100vh', marginTop: '150px' }}>
                {isEmailverified && (
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: 100 }}>
              <Lottie style={{ height: 150, width: 150, textAlign: 'center', marginTop: 100 }} animationData={success}/>
              <div style={{ textAlign: 'center' }}>You have successfully verified your account</div>
            </div>
        )}
        {!isEmailverified && (
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: 100 }}>
                <Lottie style={{ height: 150, width: 150, textAlign: 'center', marginTop: 100 }} animationData={failure}/>
                <div style={{ textAlign: 'center' }}>Unable to verify your account, please re-verify email using the app</div>
            </div>
        )}
                </section >
                <Footer />
            </div>
        </>
    )
}

export default Verify