const slider = [
    {
        slideFor: "POLICYHOLDERS",
        slideTitle: "Are you Facing Difficulty in Paying Your Life Insurance Premiums?",
        slideSubTitle: "Our 'Loan to Pay Premium' solution allows you to renew your policy and repay it in easy EMIs.",
        slideCtaLabel: "Know more",
        slideCtaLink: "/premium-financing",
        slideImg: "assets/img/slide-1.png"
    },
    {
        slideFor: "POLICYHOLDERS",
        slideTitle: "Looking for funds?",
        slideSubTitle: "Briezi offers hassle-free loans against your insurance policies.",
        slideCtaLabel: "Know more",
        slideCtaLink: "/policy-loan",
        slideImg: "assets/img/slide-2.png"
    },
    {
        slideFor: "POLICYHOLDERS",
        slideTitle: "Financial benefits meet security",
        slideSubTitle: "Policy assignment service ensures the future of your family by providing surrender value while continuing to cover your risks.",
        slideCtaLabel: "Know more",
        slideCtaLink: "/assignment",
        slideImg: "assets/img/slide-3.png"
    },
    {
        slideFor: "Insurance Companies",
        slideTitle: "Increase Customer Retention & Policy Persistency ",
        slideSubTitle: "Inviting all insurance companies to partner with Briezi for innovative ways of customer retention and financing options. ",
        slideCtaLabel: "Know more",
        slideCtaLink: "/insurers",
        slideImg: "assets/img/slide-4.png"
    },
    {
        slideFor: "Insurance Companies",
        slideTitle: "ULIP Policies Services",
        slideSubTitle: "First platform in India to offer loan services against ULIP policies.",
        slideCtaLabel: "Know more",
        slideCtaLink: "/insurers",
        slideImg: "assets/img/slide-5.png"
    },
    {
        slideFor: "Distributors",
        slideTitle: "Provide your Customers with Innovative financial Solutions",
        slideSubTitle: "Retain customers by providing Policy Assignments and Loans to Pay Premiums services",
        slideCtaLabel: "Know more",
        slideCtaLink: "/distributors",
        slideImg: "assets/img/slide-6.png"
    },
    {
        slideFor: "Banks & NBFC ",
        slideTitle: "Partner with Briezi to offer innovative financial services",
        slideSubTitle: "Partner with Briezi to offer lending services to untapped  Loan to Pay Premium services",
        slideCtaLabel: "Know more",
        slideCtaLink: "/banks",
        slideImg: "assets/img/slide-7.png"
    },
    {
        slideFor: "Investors",
        slideTitle: "Low-risk, High ROI Investment opportunity",
        slideSubTitle: "Explore investment opportunities by investing in pre-own life insurance policies.",
        slideCtaLabel: "Know more",
        slideCtaLink: "/investors",
        slideImg: "assets/img/slide-8.png"
    }
]

export const policyHolderSlides = [0,1,2]
export const insurerSlides = [3,4]
export const distributerSlide = [5]
export const bankSlide = [6]
export const investorSlide = [7]

export default slider