const Banner = (props) => {
    const { banner } = props
    return (
        <div className="row banner mx-lg-5 mx-4">
            <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
                <div className="banner-title">
                    <p>{banner.title}</p>
                </div>
                <div className="banner-subtitle my-lg-3 my-1">
                    <p>{banner.subtitle}</p>
                </div>
                <div className="py-3">
                    <div className="slide-cta primary-button mr-2">
                        <a className="nav-link" href={banner.ctaLink}  >{banner.ctaLabel}</a>
                    </div>
                    <div className="slide-cta outline-button ml-2">
                        <a className="nav-link" href={"#know-more"}  >{"Know more"}</a>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-5">
                <img src={banner.image} alt="slider-1" className="slider-img" />
            </div>
        </div>
    )
}

export default Banner;
