const Testimonial = (props) => {
    const {name, image, text} = props
    return (
        <div className="testimonial mx-lg-4 p-lg-5 mx-2 p-2">
            <div className="my-lg-4 my-2">
                <img src="assets/img/quote-left.png" alt="" className="testimonial-quote-img" />
            </div>
            <div className="my-lg-4 my-2">
                <p className="italifw-light fst-italic testimonial-text">{text}</p>
            </div>
            <div className="my-lg-4 my-2">
                <img src="assets/img/quote-right.png" alt="" className="testimonial-quote-img" />
            </div>

            <div className="d-flex my-lg-4 my-2">
                <div className="testimonial-img-round">
                    <img src={image} alt="" className="testimonial-img" />
                </div>
                <div className="d-flex flex-column ml-4 flex-row justify-content-center ">
                    <p className="testimonial-by">{name}</p>
                    <div>
                        <img src="assets/img/five-star.png" alt="" className="testimonial-star" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial