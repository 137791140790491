const faq = [
    {
        "q": "What is Policy Assignment?",
        "a": "Policy assignment is the transfer of rights of a life insurance policy from the policy owner (assignor) to a third party (assignee), typically in exchange for a financial benefit. According to Section 38 of the Insurance Act, of 1938, Policy assignment is legal in India.",
        "id": "1",
        "type": "assignment"
    },
    {
        "q": "How does Policy Assignment Work?",
        "a": "The policy owner assigns the rights of the policy to a third party (assignee) who provides a financial benefit. The assignee becomes the new beneficiary of the policy.",
        "id": "2",
        "type": "assignment"
    },
    {
        "q": "What are the Benefits of Policy Assignment?",
        "a": "Policy assignment offers surrender value by providing faster liquidity, the policyholder continues to get risk coverage and avoids payment of future premiums",
        "id": "3",
        "type": "assignment"
    }, {
        "q": "Is Policy Assignment Secure?",
        "a": "Yes, policy assignment with Briezi is a secure and hassle-free process. Your personal information and policy details are protected with advanced encryption and security measures.",
        "id": "4",
        "type": "assignment"
    },
    {
        "q": "How can I Initiate a Policy Assignment with Briezi",
        "a": "Simply log in to your Briezi account, navigate to the policy assignment section, and follow the step-by-step instructions to initiate the process securely online.",
        "id": "5",
        "type": "assignment"
    },
    {
        "q": "Can I use the loan for any type of insurance policy?",
        "a": "Are there any Fees or Charges for Policy Assignment?",
        "id": "6",
        "type": "assignment"
    },
    {
        "q": " How long does the Policy Assignment Process Take?",
        "a": "The policy assignment process with Briezi is fast and efficient, typically completed within a few days once all necessary documents and information are provided.",
        "id": "7",
        "type": "assignment"
    },
    {
        "q": "Can I Cancel or Reverse Policy Assignment?",
        "a": "Once a policy assignment is completed, it cannot be reversed or cancelled. It's important to carefully consider your decision before proceeding with the assignment",
        "id": "8",
        "type": "assignment"
    },
    {
        "q": "Will I Lose Ownership of My Policy after Assignment?",
        "a": "While the rights of the policy are transferred to the assignee you will lose the ownership of the policy, but you’ll continue to get the risk coverage as agreed upon in the assignment agreement.",
        "id": "9",
        "type": "assignment"
    },
    {
        "q": "How can I Learn More about Policy Assignment with Briezi?",
        "a": "For more information or assistance with policy assignment, please contact our customer support team or refer to the policy assignment section on the Briezi website",
        "id": "10",
        "type": "assignment"
    },
    {
        "q": "Is policy assignment legal in India?",
        "a": "According to Section 38 of the Insurance Act, of 1938, Policy assignment is legal in India.",
        "id": "11",
        "type": "assignment"
    },
    {
        "q": "What is Premium Financing?",
        "a": "Premium financing is a financial solution designed to assist policyholders in managing their premium payments by offering loans with flexible repayment options.",
        "id": "12",
        "type": "premiumFinancing"
    },
    {
        "q": "How Does Premium Financing Work?",
        "a": "Policyholders can apply for a premium financing loan to pay their insurance premium. The loan is repaid over a specified period, making premium payments more manageable with interest.",
        "id": "13",
        "type": "premiumFinancing"
    },
    {
        "q": "Can I Use Premium Financing for Any Type of Insurance Policy",
        "a": "Premium financing eligibility varies based on individual policy details. To check your policy eligibility, provide your policy details in the app or contact our support team.",
        "id": "14",
        "type": "premiumFinancing"
    },
    {
        "q": "What are the Key Benefits of Using Premium Financing?",
        "a": "Premium financing offers flexibility in premium payments, allowing policyholders to maintain coverage without losing risk coverage.",
        "id": "15",
        "type": "premiumFinancing"
    },
    {
        "q": "How Can I Apply for Premium Financing?",
        "a": "To apply for premium financing, log in to your Briezi account, navigate to the premium financing section, and follow the straightforward application process.",
        "id": "16",
        "type": "premiumFinancing"
    },
    {
        "q": "Are There Any Restrictions on Policy Age or Renewal Period?",
        "a": "Premium financing eligibility may be subject to policy details such as age and renewal periods. Confirm eligibility by providing your policy details in the app or contacting our support team.",
        "id": "17",
        "type": "premiumFinancing"
    },
    {
        "q": " Can I Use Premium Financing for Multiple Policy Renewals?",
        "a": "Yes, Briezi allows policyholders to use premium financing for multiple policy renewals. Confirm eligibility and details by providing your policy information",
        "id": "18",
        "type": "premiumFinancing"
    },
    {
        "q": "What Happens if I Miss a Premium Financing Payment?",
        "a": "Missing a payment may result in penalties or interest charges. In the event of non-repayment of the premium financing amount within the stipulated period and after exhausting all reasonable attempts to recover the outstanding amount, Briezi reserves the right to surrender the policy associated with the premium financing. Upon policy surrender, Briezi will calculate the differential amount, considering the premium financing amount, accrued interest, and any applicable bounce charges or fees. The remaining amount, after settling the outstanding dues, will be returned to the policyholder.Policyholders must adhere to the repayment schedule to avoid the surrender of the policy. Briezi will make every effort to communicate and work with the policyholder to find an amicable resolution before resorting to policy surrender.Please refer to the detailed premium financing loan agreement terms for a comprehensive understanding of the conditions and consequences associated with non-repayment.",
        "id": "19",
        "type": "premiumFinancing"
    },
    {
        "q": "Can I Pay off the Premium Financing Loan Early?",
        "a": "Yes, Briezi allows early repayment of premium financing loans but there will be pre-closure charges subject to terms and conditions.",
        "id": "20",
        "type": "premiumFinancing"
    },
    {
        "q": "Are There Any Fees for Premium Financing?",
        "a": "Briezi maintains transparent fee structures. Any fees associated with premium financing will be communicated clearly during the application process.",
        "id": "21",
        "type": "premiumFinancing"
    },
    {
        "q": "How long does the Premium Financing Approval Process Take?",
        "a": "Briezi aims for a quick and efficient approval process. Once all necessary documents are provided, approval is typically granted within a short timeframe.",
        "id": "22",
        "type": "premiumFinancing"
    },
    {
        "q": "How can I Learn More about Premium Financing at Briezi?",
        "a": "For more information or assistance with premium financing, provide your policy details in the app or contact our customer support team.",
        "id": "23",
        "type": "premiumFinancing"
    },
    {
        "q": "What is a Loan Against Insurance",
        "a": "A Loan Against Insurance is a financial option provided by Briezi, allowing policyholders to leverage the cash value of their insurance policies.",
        "id": "24",
        "type": "loan"
    },
    {
        "q": " How Does a Loan Against Insurance Work?",
        "a": "Policyholders can apply for a loan against their insurance policy, using the accumulated cash value as collateral. The loan is repaid over a specified period with agreed-upon interest.",
        "id": "25",
        "type": "loan"
    },
    {
        "q": "Can I Apply for a Loan Against Any Type of Insurance Policy?",
        "a": "Loan eligibility is based on individual policy details. To check your policy eligibility, provide your policy details in the app or contact our support team at support@briezi.com",
        "id": "26",
        "type": "loan"
    },
    {
        "q": "What are the Key Benefits of Taking a Loan Against Insurance?",
        "a": "A loan against insurance provides policyholders with the flexibility to access funds when needed without surrendering the policy. It allows you to meet financial needs while maintaining your insurance coverage.",
        "id": "27",
        "type": "loan"
    },
    {
        "q": "How Can I Apply for a Loan Against Insurance?",
        "a": " Applying for a loan against insurance is easy. Log in to your Briezi account, navigate to the loan section, and follow the straightforward application process.",
        "id": "28",
        "type": "loan"
    },
    {
        "q": "Are There Any Restrictions on Policy Age or Loan Amount?",
        "a": "Loan eligibility may vary based on policy details such as age and the surrender or fund value. Confirm eligibility and details by providing your policy information.",
        "id": "29",
        "type": "loan"
    },
    {
        "q": "Can I Take Multiple Loans Against the Same Insurance Policy?",
        "a": "No, currently Briezi doesn’t allow multiple loans against the same insurance policy.",
        "id": "30",
        "type": "loan"
    },
    {
        "q": "What Happens if I am Unable to Repay the Loan Against Insurance?",
        "a": "In the event of non-repayment of the loan amount within the stipulated period and after exhausting all reasonable attempts to recover the outstanding amount, Briezi reserves the right to surrender the policy associated with the loan.Upon policy surrender, Briezi will calculate the differential amount, considering the loan amount, accrued interest, and any applicable bounce charges or fees. The remaining amount, after settling the outstanding dues, will be returned to the policyholder.Policyholders must adhere to the repayment schedule to avoid the surrender of the policy. Briezi will make every effort to communicate and work with the policyholder to find an amicable resolution before resorting to policy surrender.Please refer to the detailed loan agreement terms for a comprehensive understanding of the conditions and consequences associated with non-repayment.",
        "id": "31",
        "type": "loan"
    },
    {
        "q": "Can I Prepay the Loan Against Insurance?",
        "a": "Yes, policyholders can prepay the loan against insurance. There will be pre-closure charges subject to the terms of the loan agreement.",
        "id": "32",
        "type": "loan"
    },
    {
        "q": " Are There Any Fees Associated with Taking a Loan Against Insurance?",
        "a": "Briezi maintains transparent fee structures. Any fees associated with taking a loan against insurance will be communicated clearly during the application process.",
        "id": "33",
        "type": "loan"
    },
    {
        "q": "How long does the Loan Against Insurance Approval Process Take?",
        "a": "Briezi aims for a quick and efficient approval process. Once all necessary documents are provided, approval is typically granted within a short timeframe.",
        "id": "34",
        "type": "loan"
    },
    {
        "q": "How can I Learn More about Loans Against Insurance at Briezi?",
        "a": "For more information or assistance with loans against insurance, provide your policy details in the app or contact our customer support team at support@briezi.com.",
        "id": "35",
        "type": "loan"
    },
    {
        "q": "What is Policy Assignment?",
        "a": "Policy assignment is the transfer of rights of a life insurance policy from the policy owner (assignor) to a third party (assignee), typically in exchange for a financial benefit. According to Section 38 of the Insurance Act, of 1938, Policy assignment is legal in India.",
        "id": "36",
        "type": "general"
    },
    {
        "q": "How does Policy Assignment Work?",
        "a": "The policy owner assigns the rights of the policy to a third party (assignee) who provides a financial benefit. The assignee becomes the new beneficiary of the policy.",
        "id": "37",
        "type": "general"
    },
    {
        "q": "What are the Benefits of Policy Assignment?",
        "a": "Policy assignment offers surrender value by providing faster liquidity, the policyholder continues to get risk coverage and avoids payment of future premiums",
        "id": "38",
        "type": "general"
    },
    {
        "q": "What is Premium Financing?",
        "a": "Premium financing is a financial solution designed to assist policyholders in managing their premium payments by offering loans with flexible repayment options.",
        "id": "39",
        "type": "general"
    },
    {
        "q": "How Does Premium Financing Work?",
        "a": "Policyholders can apply for a premium financing loan to pay their insurance premium. The loan is repaid over a specified period, making premium payments more manageable with interest.",
        "id": "40",
        "type": "general"
    },
    {
        "q": "What is a Loan Against Insurance",
        "a": "A Loan Against Insurance is a financial option provided by Briezi, allowing policyholders to leverage the cash value of their insurance policies.",
        "id": "41",
        "type": "general"
    },
    {
        "q": " How Does a Loan Against Insurance Work?",
        "a": "Policyholders can apply for a loan against their insurance policy, using the accumulated cash value as collateral. The loan is repaid over a specified period with agreed-upon interest.",
        "id": "42",
        "type": "general"
    }
]

export default faq