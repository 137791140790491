const WhySection = (props) => {
    const { title, description, iconSet, videoLink } = props.why
    return (
        <div className="why-briezi p-lg-5 px-lg-80 p-2 " id="know-more">
            <div>
                <p className="text-center why-briezi-title">{title}</p>
            </div>
            <div>
                <p className="text-center why-briezi-subtitle">{description}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between px-lg-5 px-2 mt-lg-5 mt-2">
                {iconSet.map(i => (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <img src={i.icon} alt="" className="why-briezi-icon" />
                        <p className="text-center why-briezi-icon-caption">{i.caption}</p>
                    </div>
                ))}

            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mt-lg-5 mt-2">
                <iframe sandbox width="560" height="315" className="video-player" src={videoLink} title="Briezi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default WhySection;