import Slider from "react-slick";
import Banner from "./components/Banner";
import Breadcrumbs from "./components/Breadcrumbs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServiceBlock from "./components/ServiceBlock";
import WhatIs from "./components/WhatIs";
import WhySection from "./components/WhySection";
import testimonial from "./data/testimonial";
import Testimonial from "./components/Testimonial";
import faq from "./data/faq";
import Accordian from "./components/Accordian";
import downloadLink from "./lib/Download";

function PremiumFinancing() {

    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Services'
        },
        {
            link: '/premium-financing',
            label: 'Premium Financing'
        },

    ]
  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Worried about insurance premium payments?",
        subtitle: "'Premium Financing' helps you never miss a premium payment and safeguards your family's future.",
        ctaLabel: "Pay Premium",
        ctaLink: downloadLink(),
        image: "assets/img/ipf-banner.png"
    }

    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <WhatIs
                title="What is Insurance Premium Financing?"
                description="Premium Financing is a convenient financial solution offered by Briezi that helps you pay your insurance premiums with easy EMis. Maintain your insurance coverage without the immediate financial burden, ensuring you and your loved  ones stay protected."
            />
            <WhySection
                why={{
                    title: "Why Insurance Premium Financing?",
                    description: "Now pay your premiums in easy EMIs, ensuring continuous coverage without financial strain.",
                    iconSet: [
                        {
                            icon: "assets/img/paperless-process-icon.png",
                            caption: "100% Digital Process"
                        },
                        {
                            icon: "assets/img/customer-support-icon.png",
                            caption: "Quick Approval"
                        },
                        {
                            icon: "assets/img/simple-secure-icon.png",
                            caption: "Continuous Coverage"
                        },
                        {
                            icon: "assets/img/minimal-document-icon.png",
                            caption: "Flexible Repayment Options"
                        }
                    ],
                    videoLink: "https://www.youtube.com/embed/F2IkgqgoRBc?si=IUoM-H69ckV52u4_"
                }} />

            <ServiceBlock
                title="Why Choose Premium Financing?"
                subtitle=""
                points={["Continuation of Risk Coverage: Keep your insurance coverage without the immediate financial burden, ensuring you and your loved ones stay protected.", "Flexible Repayment Options: Select repayment options that fit your financial situation.", "Quick and Easy Process: Simple application process with instant approval.", "No additional collaterals: The fund value of the policy is sufficient to finance premiums."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/why-premium-finance.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="How It Works"
                subtitle=""
                points={["Apply Online: Fill out a simple application form with your policy details.", "Get Quick Approval: Insurer reviews your application and approves it.", "Quick Disbursement: The funds are disbursed directly to the insurance company, ensuring you get continuous coverage.", "Policy Continuity: Ensure continuity of your insurance coverage even during times of financial strain."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/how-premium-finance.png"
                type="light"
                imageLocation="left"
            />
            <ServiceBlock
                title="How to apply for Insurance Premium Financing?"
                subtitle=""
                points={["Get the Briezi app", "Check policy eligibility", "Complete KYC verification", "Instant premium financing approval"]}
                ctaLabel="Download App"
                ctaLink={downloadLink()}
                image="assets/img/how-to-apply-premium-finance.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="Eligibility Criteria"
                subtitle=""
                points={["Policy Type: The policy must be a life insurance  traditional savings or ULIP policies.", "Policy Tenure: Premium payments for traditional insurance policies must be made for at least 3 years and 1 year for ULIPs.", "Policy Value: The policy must have accumulated sufficient cash value to support the premium financing amount requested.", "Valid Documents: Valid Aadhar card, PAN card, and Policyholders Bank Details."]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/eligibility-premium-finance.png"
                type="light"
                imageLocation="left"
            />
            <div className="testimonial-box p-lg-5 p-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">What Our</span>
                    </div>
                    <div className="fancy-box mx-1">
                        <span>Customers</span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <span className="testimonial-box-title">Say</span>
                    </div>
                </div>
                <div className="p-5">
                    <Slider {...testimonialSettings}>
                        {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
                    </Slider>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.filter(f=>f.type==='premiumFinancing').map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <ContactUs />
            <Footer />
        </div>
    );
}

export default PremiumFinancing;
