import Slider from "react-slick"
import Banner from "./components/Banner"
import Breadcrumbs from "./components/Breadcrumbs"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import Header from "./components/Header"
import ServiceBlock from "./components/ServiceBlock"
import testimonial from "./data/testimonial"
import Testimonial from "./components/Testimonial"
import Stats from "./components/Stats"
import Partner from "./components/Partner"
import faq from "./data/faq"
import Accordian from "./components/Accordian"
import WhatIs from "./components/WhatIs"
import solutions, { bankSolution } from "./data/solution"
import SolutionItem from "./components/SolutionItem"

const Banks = () => {
    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Partner With Us'
        },
        {
            link: '/banks',
            label: 'Banks/NBFCs'
        },

    ]
  const solutionSettings = {
    dots: false,
    infinite: true,
     autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Partner with Briezi - Unlock New Revenue Streams with Secure Insurance-Based Lending",
        subtitle: "Offer secure lending against insurance policies and expand your loan book with AAA-rated products.",
        ctaLabel: "Connect Now",
        ctaLink: "/contactus",
        image: "assets/img/bank-banner.png"
    }
    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />
            <ServiceBlock
                title="Why partner with Briezi?"
                subtitle="By partnering with Briezi, your company can leverage the following benefits:"
                points={["Increase Loan Book Size","Comprehensive Financial Solutions","Secure AAA-Rated Products","Digital Integration","Partnership with Leading Insurers"]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/bank-partner.png"
                type="primary"
                imageLocation="left"
            />
            <div className="p-lg-5 p-2  solution-box">
                <div className="my-lg-3 my-1">
                    <p className="text-center whatIs-title">{"Our Financial Services"}</p>
                </div>
                <div className="px-lg-5 px-2">
                    <p className="text-sm-center text-justify whatIs-description px-5">{"At Briezi, we collaborate with Banks and NBFCs to provide innovative financial services against insurance policies. Our solutions are designed to enhance your lending offerings with secure, high-quality products."}</p>
                </div>
                <Slider {...solutionSettings}>
                    {bankSolution.map(s => (<SolutionItem {...s} />))}
                </Slider>
            </div>
            <div className={"ulip-box row px-5"}>
                <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                    <p className={`service-title`}>{"Exclusive Financial Services for ULIP Policies"}</p>
                    <p className={`service-subtitle`}>{"Briezi is the only insurtech company in India offering financial services for ULIP policies. We provide unique solutions to meet the financial needs of policyholders while ensuring they continue to benefit from their investments."}</p>
                </div>
                <div className="col-12 col-lg-6 my-3">
                    <img src={"assets/img/ulip.png"} className="slider-img" alt="" />
                </div>
            </div>
            <div className={"success-box row px-5"}>
                <div className="col-12 col-lg-6 my-3">
                    <img src={"assets/img/max-success-logo.png"} className="slider-img" alt="" />
                </div>
                <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                    <p className={`service-title`}>{"Success Story with Insurance Companies"}</p>
                    <p className={`service-subtitle`}>{"Our completely digital and seamless process has successfully increased persistency among Max Life policyholders."}</p>
                </div>
            </div>
            <ServiceBlock
                title="How Our Partnership Works"
                subtitle=""
                points={["Untapped Financing in Insurance: Explore the vast potential of financing within the insurance sector. Our platform helps you unlock new opportunities and expand your loan portfolio.",
                    "Market Potential Analysis: Gain insights into the market potential with our comprehensive analysis. Understand the demand for premium financing and loans against insurance policies.",
                    "Digital Process & Integration: Benefit from our streamlined digital processes. We ensure seamless integration with your existing systems, making the partnership effortless and efficient.",
                    "Policy Assignment as Security: Secure your loans with policy assignments. This unique feature provides additional security, reducing risk and enhancing the stability of your loan book."
                ]}
                ctaLabel="Connect Now"
                ctaLink=""
                image="assets/img/insurer-partnership.png"
                type="primary"
                imageLocation="right"
            />
            <div className="partner-section my-4">
            <p className="partner-text text-center">Our Partner NBFC</p>
            <div>
                <img className="partner-logo" src="assets/img/nbfc-logo.png" alt="max logo" />
            </div>

        </div >

            <Stats />
            <div className="contact-us-section container-fluid">
                <div className="row">
                    <div className="col-12  my-4">
                        <p className="text-center contactus-title">Ready to Partner with Briezi?</p>
                    </div>
                    <div className="col-12">
                        <p className="text-center">Join the growing list of financial institutions leveraging our solutions to enhance their loan portfolios and get secure returns.</p>
                    </div>

                    <div className="d-flex justify-content-center align-item-center col-12 mb-4">
                        <div className="slide-cta primary-button my-3">
                            <a className="nav-link myHref" href={"mailto:info@briezi.com"}  >{"Contact Us"}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.filter(f=>f.type==='general').map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Banks