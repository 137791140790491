const Header = () => {
    return (
        <div className="fixed-header">
            <nav className="navbar fixed-top navbar-light bg-white px-2 px-lg-80 px-lg-2 pc-3 py-lg-3 py-1">
                <a className="navbar-brand" href="/">
                    <img src="assets/img/logo.png" alt="site logo" className="main-logo" />
                </a>
                <button class="navbar-toggler nav justify-content-end  d-block d-sm-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <img className="menu-icon" src="assets/img/menu.png" alt="" />
                </button>
                <ul className="nav justify-content-end header-nav d-sm-flex d-none">
                    <li className="nav-item">
                        <a className="nav-link active" href="/">Home</a>
                    </li>
                    <div class="dropdown">
                        <li className="nav-item" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
                            <a className="nav-link" href="javascript:void(0)" >Service
                                <img src='assets/img/circle-arrow.png' className="nav-icon" />
                            </a>
                        </li>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="/premium-financing">Premium Financing</a>
                            <a class="dropdown-item" href="/policy-loan">Policy Loan</a>
                            <a class="dropdown-item" href="/assignment">Assignment</a>
                        </div>
                    </div>
                    <div class="dropdown">
                        <li className="nav-item" id="dropdownPartnerButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            <a className="nav-link" href="#">Partner With Us
                                <img src='assets/img/circle-arrow.png' className="nav-icon" />
                            </a>
                        </li>
                        <div class="dropdown-menu" aria-labelledby="dropdownPartnerButton">
                            <a class="dropdown-item" href="/insurers">Insurers</a>
                            <a class="dropdown-item" href="/banks">Banks/NBFC</a>
                            <a class="dropdown-item" href="/investors">Investors</a>
                            <a class="dropdown-item" href="/distributors">Distributors</a>
                        </div>
                    </div>
                    <li className="nav-item">
                        <a className="nav-link" href="/aboutus">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contactus">Contact Us</a>
                    </li>
                    {/*  <li className="nav-item dark-button mx-3">
                        <a className="nav-link">Signup</a>
                    </li> */}
                    <li className="nav-item primary-button mx-3">
                        <a className="nav-link myHref" href="javascript:void(0)"  >Get App</a>
                    </li>
                </ul>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav justify-content-end header-nav d-block">
                        <li className="nav-item">
                            <a className="nav-link active text-right" href="/">Home</a>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target={`#collapse-services`} aria-controls={`collapse-services`}>
                            <a className="nav-link  text-right" href="javascript:void(0)">Service</a>
                            <div id={`collapse-services`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">

                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/premium-financing">Premium Financing</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/policy-loan">Policy Loan</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/assignment">Assignment</a>
                                </li>
                            </div>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target={`#collapse-partner`} aria-controls={`collapse-partner`}>
                            <a className="nav-link  text-right" href="javascript:void(0)">Partner With Us</a>
                            <div id={`collapse-partner`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">

                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/insurers">Insurers</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/banks">Banks/NBFC</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/investors">Investors</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-right" href="/distributors">Distributors</a>
                                </li>
                            </div>
                        </li>
                        {/*  <li className="nav-item">
                            <a className="nav-link text-right" href="/premium-financing">Premium Financing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-right" href="/policy-loan">Policy Loan</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-right" href="/assignment">Assignment</a>
                        </li> */}
                        {/*                         <li className="nav-item">
                            <a className="nav-link text-right" href="#">Partner With Us
                                <img src='assets/img/circle-arrow.png' className="nav-icon" />
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link text-right" href="/aboutus">About Us</a>
                        </li>
                        {/* <div className="d-flex justify-content-end mb-2">
                            <li className="nav-item dark-button">
                                <a className="nav-link text-right">Signup</a>
                            </li>
                        </div> */}
                        <div className="d-flex justify-content-end">
                            <li className="nav-item primary-button" >
                                <a className="nav-link text-right myHref" href="javascript:void(0)" >Get App</a>
                            </li>
                        </div>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header;
