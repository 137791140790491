const Breadcrumbs = (props) => {
    const {crumbs} = props
    return (
        <nav className="breadcrumb-nav px-lg-5 px-2" aria-label="breadcrumb">
            <ol class="breadcrumb">
                {crumbs.map((c,index) => (
                     <li class={`breadcrumb-item ${crumbs.length - 1 === index ? 'active' : ''}`}><a href={c.link}>{c.label}</a></li>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumbs;