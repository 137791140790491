const Accordian = (props) => {
    return (
        <div className="card my-3">
            <div className="card-header" id="headingOne">
                <p className="mb-0">
                    <div data-toggle="collapse" data-target={`#collapse${props.faq.id}`} aria-controls={`collapse${props.faq.id}`}>
                        <span>{props.faq.q}</span>
                    </div>
                </p>
            </div>

            <div id={`collapse${props.faq.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                    {props.faq.a}
                </div>
            </div>
        </div>
    )
}

export default Accordian