const WhatIs = (props) => {
    return (
        <div className="p-lg-5 p-2 whatIs-box">
            <div className="my-lg-3 my-1">
                <p className="text-center whatIs-title">{props.title}</p>
            </div>
            <div className="px-lg-5 px-2">
                <p className="text-lg-center text-justify whatIs-description px-5">{props.description}</p>
            </div>
        </div>
    )
}

export default WhatIs