const ContactUs = () => {
    return (
        <div className="contact-us-section container-fluid">
            <div className="row">
                <div className="col-12  my-4">
                    <p className="text-center contactus-title">Contact Us</p>
                </div>
                <div className="col-12">
                    <p className="text-center">Feel free to reach out to us for any inquiries or assistance. We're here to help!</p>
                </div>
                
                <div className="d-flex justify-content-center align-item-center col-12 mb-4">
                <div className="slide-cta primary-button my-3">
                        <a className="nav-link myHref" href={"mailto:info@briezi.com"}  >{"Contact Now"}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
