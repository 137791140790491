import Slider from "react-slick"
import Banner from "./components/Banner"
import Breadcrumbs from "./components/Breadcrumbs"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import Header from "./components/Header"
import ServiceBlock from "./components/ServiceBlock"
import testimonial from "./data/testimonial"
import Testimonial from "./components/Testimonial"
import Stats from "./components/Stats"
import Partner from "./components/Partner"
import faq from "./data/faq"
import Accordian from "./components/Accordian"
import WhatIs from "./components/WhatIs"
import solutions, { bankSolution, distributorSoution } from "./data/solution"
import SolutionItem from "./components/SolutionItem"
import downloadLink, { downloadPartnerLink } from "./lib/Download"

const Distributor = () => {
    const crumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/',
            label: 'Partner With Us'
        },
        {
            link: '/distributors',
            label: 'Distributors'
        },

    ]
  const solutionSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
    const banner = {
        title: "Empower Your Business with Briezi Partner App",
        subtitle: "Boost your sales and customer retention with our dedicated platform for insurance distributors.",
        ctaLabel: "Download the App",
        ctaLink: downloadPartnerLink(),
        image: "assets/img/distributor-banner.png"
    }
    return (
        <div>
            <Header />
            <div className="px-lg-5 px-2">
                <Breadcrumbs crumbs={crumbs} />
            </div>
            <Banner banner={banner} />

            <div className="p-lg-5 p-2  solution-box">
                <div className="my-lg-3 my-1">
                    <p className="text-center whatIs-title">{"Why Partner with Briezi?"}</p>
                </div>
                <div className="px-lg-5 px-2">
                    <p className="text-center whatIs-description px-5">{"At Briezi, we provide insurance distributors with innovative tools and services to enhance their business offerings and earn money. Our Briezi Partner App enables seamless access to premium financing, policy assignments, and loans against insurance policies, ensuring your clients receive the best financial support."}</p>
                </div>
                <Slider {...solutionSettings}>
                    {distributorSoution.map(s => (<SolutionItem {...s} />))}
                </Slider>
            </div>
            <ServiceBlock
                title="Your Success, Our Priority"
                subtitle="The Briezi Partner app is designed to simplify and enhance your operations. With real-time updates, seamless integrations, and comprehensive support, you can focus on what matters most – serving your clients."
                points={["Automated customer follow-up reminders",
                    "Real-time policy tracking and updates",
                    "In-depth analytics and performance reports",
                    "Easy-to-use mobile application"
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/success-step.png"
                type="light"
                imageLocation="left"
            />
                        <ServiceBlock
                title="Benefits of Partnering with Briezi"
                subtitle="The Briezi Partner app is designed to simplify and enhance your operations. With real-time updates, seamless integrations, and comprehensive support, you can focus on what matters most – serving your clients."
                points={["Increased Revenue Streams : Offer your clients innovative financial solutions that drive sales.",
                    "Comprehensive Support : Get access to training, resources, and dedicated support to help you succeed.",
                    "Enhanced Customer Loyalty : Utilize our tools to improve client satisfaction and retention.",
                    "Market Expansion : Leverage our platform to reach a wider audience and grow your business."
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/partner-benefit.png"
                type="primary"
                imageLocation="right"
            />
            <ServiceBlock
                title="Briezi Partner App Features"
                subtitle=""
                points={["Premium Financing: Offer clients the option to finance their insurance premiums, making it easier for them to maintain coverage.",
                    "Policy Assignments: Facilitate policy assignments for clients looking for alternative financial solutions.",
                    "Loans Against Insurance: Help clients leverage their insurance policies to secure quick loans.",
                    "Other Tools – Manage your sales, track your performance, and access exclusive financial services through our dedicated app."
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/partner-app.png"
                type="light"
                imageLocation="left"
            />
            <ServiceBlock
                title="How It Works?"
                subtitle=""
                points={[
                    "Download the App – Install the Briezi Partner app from the Google Play Store.",
                    "Register – Create an account and complete your profile.",
                    "Get Started – Access a wealth of resources and tools to enhance your distribution efforts.",
                    "Offer Services – Offer exclusive financial solutions to your clients."
                ]}
                ctaLabel=""
                ctaLink=""
                image="assets/img/how-partner-app.png"
                type="primary"
                imageLocation="right"
            />
            <div className="partner-section my-4">
            <p className="partner-text text-center">Our Partner NBFC</p>
            <div>
                <img className="partner-logo" src="assets/img/nbfc-logo.png" alt="max logo" />
            </div>

        </div >
  
            <Stats />
            <div className="contact-us-section container-fluid">
                <div className="row">
                    <div className="col-12  my-4">
                        <p className="text-center contactus-title">Join Briezi Today</p>
                    </div>
                    <div className="col-12">
                        <p className="text-center">Become a part of the Briezi network and start offering enhanced financial solutions to your clients.</p>
                    </div>

                    <div className="d-flex justify-content-center align-item-center col-12 mb-4">
                        <div className="slide-cta primary-button my-3">
                            <a className="nav-link myHref" href={downloadPartnerLink()}  >{"Download Briezi Partner App"}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-box p-lg-5 p-2">
                <div>
                    <p className="text-center faq-title">Frequently Asked Questions</p>
                </div>
                <div className=" p-5">
                    <div id="accordion">
                        {faq.filter(f=>f.type==='general').map(f => (<Accordian faq={f} />))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Distributor