const values = [
    {
        valueFor: ' POLICYHOLDERS ',
        valueSubtitle: 'Empowering customers to access quick funds against life insurance policies',
        valueCtaLabel: 'Explore Services',
        valueCtaLink: '/'
    },
    {
        valueFor: ' INSURANCE COMPANIES ',
        valueSubtitle: 'Increase customer retention and policy persistency.',
        valueCtaLabel: 'Partner with Us',
        valueCtaLink: '/'
    },
    {
        valueFor: ' DISTRIBUTORS ',
        valueSubtitle: 'Expand your services and support policyholders effectively. ',
        valueCtaLabel: 'Partner with Us',
        valueCtaLink: '/'
    },
    {
        valueFor: ' BANKS / NBFCs ',
        valueSubtitle: 'Partner with us to offer innovative financial solutions to policyholders.',
        valueCtaLabel: 'Connect Now',
        valueCtaLink: '/'
    },
    {
        valueFor: ' INVESTORS ',
        valueSubtitle: 'Invest in secure preowned life insurance policies, which gives you high returns.',
        valueCtaLabel: 'Know more',
        valueCtaLink: '/'
    }
]

export default values