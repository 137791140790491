const CarouselSlide = (props) => {
    const {slide} = props
    return (
        <div className="row banner mx-2 mx-lg-5">
            <div className="col-12 col-sm-7 px-2 px-sm-3 px-lg-5 carousel-slide">
                <div className="slide-for">
                    <p>{slide.slideFor}</p>
                </div>
                <div className="slide-title">
                    <p>{slide.slideTitle}</p>
                </div>
                <div className="slide-subtitle">
                    <p>{slide.slideSubTitle}</p>
                </div>
                <div className="slide-cta primary-button">
                    <a className="nav-link" href={slide.slideCtaLink}  >{slide.slideCtaLabel}</a>
                </div>
            </div>
            <div className="col-12 col-sm-5">
                <img src={slide.slideImg} alt="slider-1" className="slider-img" />
            </div>
        </div>
    )
}

export default CarouselSlide;
