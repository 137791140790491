const testimonial = [
    {
        name: "Karthik",
        text: "I got a loan quickly without losing my policy benefits. Briezi made the process easy and stress-free. Highly recommended! ",
        image: "assets/img/testimonial-1.jpg" 
    },
    {
        name: "Vishnu",
        text: "Briezi made getting a loan on my insurance policy quick and hassle-free. I accessed funds without compromising my insurance coverage.",
        image: "assets/img/testimonial-2.jpg" 
    },
    {
        name: "Dhruv",
        text: "Briezi provided the funds I needed without any hassle. The process was straightforward and quick. Highly recommend!",
        image: "assets/img/testimonial-3.jpg" 
    },  
]

export default testimonial