import { pathOr } from "ramda";

const ServiceBlock = (props) => {
    const { type, imageLocation, title, subtitle, points, ctaLabel, ctaLink, image } = props

    return (
        <div className={type + "Box row px-lg-5 px-lg-80 p-5"} id="know-more">
            {imageLocation === 'left' && (
                <div className="col-12 col-lg-6 my-3">
                    <img className="slider-img" src={image} alt="" />
                </div>
            )}
            <div className="col-12 col-lg-6 px-4 my-3 d-flex flex-column justify-content-center">
                <p className={`service-title ${type === 'primary' ? 'white' : 'black'}`}>{title}</p>
                <p className={`service-subtitle ${type === 'primary' ? 'white' : 'black'}`}>{subtitle}</p>
                <div>
                    {points.map(p => {
                            const splitTitle = p.split(":");
                            let boldTitle = ""
                            let regularTitle = ""
                            if(splitTitle.length > 1){
                                boldTitle = pathOr("", ["0"], splitTitle)
                                regularTitle = pathOr("", ["1"], splitTitle)
                            } else 
                            {
                                boldTitle = ""
                                regularTitle = p
                            }
                        return (
                            <div className="d-flex">
                                <div>
                                    <img src={`assets/img/chevron${type === 'light' ? '-dark' : ''}.png`} alt="" className="chevron" />
                                </div>
                                <div>
                                    <p className={`service-points ${type === 'primary' ? 'white' : 'black'}`}><span className="boldTitle">{boldTitle!="" ? boldTitle + ":" : ""}</span>{regularTitle}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {ctaLabel !== "" && (
                    <div className="slide-cta secondary-button my-3">
                        <a className="nav-link" href={ctaLink}  >{ctaLabel}</a>
                    </div>
                )}
            </div>
            {imageLocation === 'right' && (
                <div className="col-12 col-lg-6 my-3">
                    <img src={image} className="slider-img" alt="" />
                </div>
            )}
        </div>
    )
}

export default ServiceBlock