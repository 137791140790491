import CarouselSlide from "./components/CarouselSlide";
import Header from "./components/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider, { bankSlide, distributerSlide, insurerSlides, investorSlide, policyHolderSlides } from "./data/slider";
import ServiceBlock from "./components/ServiceBlock";
import { useState } from "react";
import { useRef } from "react";
import values from "./data/values";
import Testimonial from "./components/Testimonial";
import testimonial from "./data/testimonial";
import Accordian from "./components/Accordian";
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import faq from "./data/faq";
import WhySection from "./components/WhySection";
import Stats from "./components/Stats";

function App() {
  const sliderRef = useRef(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [activeSlide, setActiveSlide] = useState(0)

  const ValuesBox = () => {
    return values.map((v) => (
      <div className="values-container">
        <div className="value-title">
          <span className="valueFor">{v.valueFor}</span>
        </div>
        <div className="d-flex flex-row values-points  align-items-center">
          <span className="values-points-subtitle">
            {v.valueSubtitle} <a href={v.valueCtaLink}>{v.valueCtaLabel}</a>
          </span>
          <img src="assets/img/chevron-green.png" alt="" className="chevron-green" />
        </div>
      </div>
    ))
  }

  return (
    <div>
      <Header />
      <Slider ref={sliderRef} {...settings} afterChange={(currentSlide) => {
        setActiveSlide(currentSlide)
      }}>
        {slider.map((slides) => (
          <CarouselSlide slide={slides} />
        ))}
      </Slider>
      <div className="banner-controls d-sm-flex d-none flex-row align-items-center justify-content-center">
        <div className={`control-pill ${policyHolderSlides.includes(activeSlide) ? 'active-pill' : ""}`} onClick={() => sliderRef.current.slickGoTo(policyHolderSlides[0])}>
          <span>Policyholders</span>
        </div>
        <div className={`control-pill ${insurerSlides.includes(activeSlide) ? 'active-pill' : ""}`} onClick={() => sliderRef.current.slickGoTo(insurerSlides[0])}>
          <span>Insurer</span>
        </div>
        <div className={`control-pill ${distributerSlide.includes(activeSlide) ? 'active-pill' : ""}`} onClick={() => sliderRef.current.slickGoTo(distributerSlide[0])} >
          <span>Distributors</span>
        </div>
        <div className={`control-pill ${bankSlide.includes(activeSlide) ? 'active-pill' : ""}`} onClick={() => sliderRef.current.slickGoTo(bankSlide[0])}>
          <span>Banks/ NBFC</span>
        </div>
        <div className={`control-pill ${investorSlide.includes(activeSlide) ? 'active-pill' : ""}`} onClick={() => sliderRef.current.slickGoTo(investorSlide[0])}>
          <span>Investors</span>
        </div>
      </div>
      <WhySection 
        why= {{
          title: "Why Briezi?",
          description: "Briezi offers seamless policy financing solutions tailored to your needs.",
          iconSet: [
            {
              icon: "assets/img/customer-support-icon.png",
              caption: "Access Funds Easily"
            },
            {
              icon: "assets/img/paperless-process-icon.png",
              caption: "Quick Disbursement"
            },
            {
              icon: "assets/img/simple-secure-icon.png",
              caption: "Simple & Secure Process"
            },
            {
              icon: "assets/img/minimal-document-icon.png",
              caption: "End-to-End Digital Process"
            }
          ],
          videoLink: "https://www.youtube.com/embed/CevYS_p-BiQ?si=SJYLlj3omO9rawup"
        }}
      />
      <ServiceBlock
        title="Loan to Pay Premium"
        subtitle="Pay your life insurance premium in easy EMIs."
        points={["Quick Approval", "Continue to get risk coverage.", "Flexible EMIs"]}
        ctaLabel="Know More"
        ctaLink="/premium-financing"
        image="assets/img/premium-finance-service.png"
        type="primary"
        imageLocation="right"
      />
      <ServiceBlock
        title="Policy Assignment"
        subtitle="Assign your policy for financial consideration while retaining risk coverage at zero cost."
        points={["Fast access to your policy surrender amount", "Stress free online process", "Continue to get risk coverage", "No need to pay future premiums"]}
        ctaLabel="Know More"
        ctaLink="/assignment"
        image="assets/img/assignment-service.png"
        type="light"
        imageLocation="left"
      />
      <ServiceBlock
        title="Loan on Insurance"
        subtitle="Avail hassle-free loans against your life insurance policy."
        points={["Quick Disbursement", "No credit checks", "Flexible EMIs"]}
        ctaLabel="Know More"
        ctaLink="/policy-loan"
        image="assets/img/loan-service.png"
        type="primary"
        imageLocation="right"
      />
      <div className="px-lg-5 px-lg-80 px-2">
        <div className="mt-lg-5 mt-2 ">
          <p className="text-center values-title">Creating value for all our Stakeholders</p>
        </div>
        <div className="row my-lg-5 px-lg-4 my-2 px-2">
          <div className="col-12 col-lg-6">
            <img src="assets/img/values-stakeholder.png" alt="" className="slider-img" />
          </div>
          <div className="col-12 col-lg-6">
            <ValuesBox />
          </div>
        </div>
      </div>
      <ServiceBlock
        title="Policyholder is a Winner"
        subtitle="Empowering You for Financial Success: At Briezi, we believe every policyholder is a winner. Plan for your future, secure your retirement, and protect your loved ones with our services."
        points={["Access Quick Funds", "Continue to get risk coverage for your family.", "Flexible repayment options"]}
        ctaLabel=""
        ctaLink=""
        image="assets/img/policyholder-winner.png"
        type="primary"
        imageLocation="right"
      />
      <Stats />
      <div className="testimonial-box p-lg-5 px-lg-80 p-2">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <span className="testimonial-box-title">What Our</span>
          </div>
          <div className="fancy-box mx-1">
            <span>Customers</span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <span className="testimonial-box-title">Say</span>
          </div>
        </div>
        <div className="p-5 p-lg-5 px-lg-80">
          <Slider {...testimonialSettings}>
            {testimonial.map(t => (<Testimonial name={t.name} image={t.image} text={t.text} />))}
          </Slider>
        </div>
      </div>
      <div className="faq-box p-lg-5 px-lg-80 p-2">
        <div>
          <p className="text-center faq-title">Frequently Asked Questions</p>
        </div>
        <div className="p-lg-5 p-2">
          <div id="accordion" className="px-lg-80">
            {faq.filter(f=>f.type==='general').map(f => ( <Accordian faq={f} />))}
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
